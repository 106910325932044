// The button itself

.theme-changer {
  position: fixed;
  top: 0;
  left: 0;
  padding: 0.8em 1.4em;
  border: none;
  border-radius: $border-radius;
  margin: 1em;
  z-index: 999;
  font-family: "Nunito", sans-serif;
  cursor: pointer;
  transition: 1s;
  box-shadow: $box-shadow-default;

  &:hover {
    transition: 1s;
  }
}

// Default

.default {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.default::-webkit-scrollbar-track {
  background-color: $main-colour;
}

.default::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
  background-color: $secondary-colour;
  border-radius: 10px;
}

.default::-webkit-scrollbar-thumb {
  background-color: $secondary-colour;
  border-radius: 10px;
}

// Light theme

.light-theme::-webkit-scrollbar-track {
  background-color: $colour-light-theme-secondary;
}

.light-theme::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
  background-color: $colour-light-theme-main;
  border-radius: 10px;
}

.light-theme::-webkit-scrollbar-thumb {
  background-color: $colour-light-theme-main;
  border-radius: 10px;
}

.light-theme {
  background: radial-gradient(
    circle,
    rgba(230, 164, 180, 1) 0%,
    rgba(255, 248, 227, 1) 100%
  );
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  .theme-changer {
    background-color: $colour-light-theme-main;
    color: $pure-black;
  }

  header {
    background-color: transparent;

    img#Me {
      background: radial-gradient(
        circle,
        rgba(230, 164, 180, 1) 0%,
        rgba(245, 238, 230, 1) 100%
      );
    }

    h1,
    h2 {
      color: $pure-black;
      text-shadow: none;
    }

    &:before,
    &:after {
      display: none;
    }
    .date-and-time {
      background: $colour-light-theme-secondary;
      color: $pure-black;
  }
  }

  #welcome {
    background: transparent;
    .welcome-panel {
      background-color: $colour-light-theme-secondary;
      color: $pure-black;
      box-shadow: $box-shadow-default;
    }
  }

  .blogfeed {
    background-color: transparent;

    h2 {
      color: $pure-black;
    }
  }

  .blogposts {
    background-color: $colour-light-theme-secondary;
    box-shadow: $box-shadow-default;

    .blogtitle,
    h4,
    p {
      color: $pure-black;
    }

    a {
      color: $pure-black;
      background-color: $colour-light-theme-main;
    }

    .more {
      &:hover {
        background-color: $colour-light-theme-main;
      }
    }
  }

  .Skills {
    h2 {
      color: $pure-black;
      font-weight: 700;
    }
  }

  .professional-timeline {
    background: transparent;
  }

  .timeline:before {
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 3%,
      rgb(243, 215, 202) 5%,
      rgb(243, 215, 202) 82%,
      rgba(0, 0, 0, 0) 00%
    );
  }

  .direction-r .flag {
    background-color: $colour-light-theme-accent;
    color: $pure-black;
    box-shadow: inset 5px 0 0 0 $colour-light-theme-accent;

    &:after {
      border-right-color: $colour-light-theme-accent;
    }
  }

  .direction-l .flag {
    background-color: $colour-light-theme-accent;
    color: $pure-black;
    box-shadow: inset 5px 0 0 0 $colour-light-theme-accent;

    &:after {
      border-left-color: $colour-light-theme-accent;
    }
  }

  .time {
    color: $pure-black;
  }

  footer {
    background-color: $colour-light-theme-secondary;

    p {
      color: $pure-black;
    }

    a {
      color: $pure-black;
    }

    .fa-github {
      background-color: $colour-light-theme-main;
    }
    .fa-codepen {
      background-color: $colour-light-theme-main;
    }
  }
}
