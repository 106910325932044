// BODY

body {
  background-color: $main-colour;
  margin: 0;
  padding: 0;
  font-size: 16px;
  margin: auto;
  font-family: "Nunito", sans-serif;
}

body a {
  transition: 1s;

  &:hover {
    transition: 1s;
  }
}

body::-webkit-scrollbar-track {
  background-color: $main-colour;
}

body::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
  background-color: $secondary-colour;
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
  background-color: $secondary-colour;
  border-radius: 10px;
}

img {
  max-width: 100%;
  transition: 1s;
}

// HEADINGS

h1,
h2,
h3 {
  font-family: "Nunito", sans-serif;
}

h1 {
  font-size: 3em;
  font-weight: 700;
}

h2 {
  font-size: 2em;
}

h3 {
  font-size: 1em;
}

// WRAPPER

.wrapper {
  width: 80%;
  max-width: 1200px;
  margin: auto;
  height: 100%;
}


// RUDIMENTARY GRID

.grid {
  display: grid;
}

.grid-3-3-3 {
  grid-template-columns: 31% 31% 31%;
  gap: 3%;
  justify-content: space-between;
}