$background-bg: $secondary-colour;
$arrow-flair: black;
$stop-border: black;
$stop-inside: white;
$flag-bg: $arrow-flair;
$flag-color: white;

.professional-timeline {
  background: linear-gradient(180deg, #161616 0%, #3E885B 15%, #3E885B 99%, #161616 100%);
  padding: 13em 0 6em;
  border-radius: $border-radius;

  h2 {
    text-align: center;
    font-weight: bold;
    padding: 1em 0;
  }

  p {
    max-width: 660px;
    margin: 5vh auto;
    text-align: center;
    font-size: 1.1em;

    line-height: 1.1em;
  }
}

.timeline {
  position: relative;
  width: 660px;
  margin: 0 auto;
  margin-top: 20px;
  padding: 0 0;
  list-style-type: none;

  &:before {
    position: absolute;
    left: 50%;
    top: 0;
    content: " ";
    display: block;
    width: 6px;
    height: 100%;
    margin-left: -3px;
    background: linear-gradient(to bottom,
        rgba(0, 0, 0, 0) 3%,
        rgb(0, 0, 0) 5%,
        rgb(0, 0, 0) 82%,
        rgba(0, 0, 0, 0) 00%);
    z-index: 5;
  }

  li {
    padding: 1.5em 0;

    &:after {
      content: "";
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
    }
  }
}

.direction-l {
  position: relative;
  width: 310px;
  float: left;
  text-align: right;
}

.direction-r {
  position: relative;
  width: 310px;
  float: right;
}

.flag-wrapper {
  position: relative;
  display: inline-block;
  text-align: center;
}

.flag {
  position: relative;
  display: inline;
  background: $flag-bg;
  color: $flag-color;
  box-shadow: inset 5px 0 0 0 $arrow-flair;
  padding: 0.5em 1.5em;
  border-radius: $border-radius;
  font-weight: 500;
  letter-spacing: 0.03em;
  text-align: left;
}

.flag-l {
  box-shadow: inset -5px 0 0 0 $arrow-flair;
}

.direction-l .flag:before,
.direction-r .flag:before {
  position: absolute;
  top: 50%;
  right: -30px;
  content: " ";
  display: block;
  width: 12px;
  height: 12px;
  margin-top: -10px;
  background: $stop-inside;
  border-radius: 10px;
  border: 4px solid $stop-border;
  z-index: 10;
}

.direction-r .flag:before {
  left: -30px;
}

.direction-l .flag:after {
  content: "";
  position: absolute;
  left: 99%;
  top: 50%;
  height: 0;
  width: 0;
  margin-top: -8px;
  border: solid transparent;
  border-left-color: $arrow-flair;
  border-width: 8px;
  pointer-events: none;
}

.direction-r .flag:after {
  content: "";
  position: absolute;
  right: 99%;
  top: 50%;
  height: 0;
  width: 0;
  margin-top: -8px;
  border: solid transparent;
  border-right-color: $arrow-flair;
  border-width: 8px;
  pointer-events: none;
}

.time-wrapper {
  display: inline;
  line-height: 1em;
  color: rgb(250, 80, 80);
  vertical-align: middle;
}

.direction-l .time-wrapper {
  float: left;
}

.direction-r .time-wrapper {
  float: right;
}

.time {
  display: inline-block;
  padding: 4px 6px;
  font-size: 0.8em;
  color: $pure-white;
}

.desc {
  margin: 2em 0 0 0;
  font-size: 1em;
  line-height: 1.5em;
  color: $pure-black;

  span {
    font-weight: 600;
    font-size: 1.1em;
  }

  hr {
    opacity: 0.5;
  }
}

.direction-r .desc {
  margin: 2em 0 0 0;
}

@media screen and (max-width: 990px) {
  .professional-timeline {
    padding: 5%;
    border-radius: 0;

    h2 {
      padding-bottom: 0;
    }
  }



  .timeline {
    width: 100%;
    padding: 1em 0 1em 0;
  }

  .timeline li {
    padding: 1.5em 0;
  }

  .direction-l,
  .direction-r {
    float: none;
    width: 100%;
    z-index: 99;

    text-align: center;
  }

  .flag-wrapper {
    text-align: center;
  }

  .flag {
    z-index: 15;
    box-shadow: none;
  }

  .direction-l .flag:before,
  .direction-r .flag:before {
    position: absolute;
    top: 0px;
    left: 50%;
    content: " ";
    display: block;
    width: 12px;
    height: 12px;
    margin-left: -10px;
    background: $pure-white;
    border-radius: 10px;
    border: 4px solid black;
    z-index: 10;
  }

  .direction-l .flag:after,
  .direction-r .flag:after {
    content: "";
    position: absolute;
    left: 50%;
    top: -8px;
    height: 0;
    width: 0;
    margin-left: -8px;
    border: solid transparent;
    border-bottom-color: black;
    border-width: 8px;
    pointer-events: none;
  }

  .time-wrapper {
    display: block;
    position: relative;
    margin: 4px 0 0 0;
    z-index: 14;
  }

  .time {
    background-color: $pure-white;
    color: $pure-black;
    border-radius: 20px;

  }

  .direction-l .time-wrapper {
    float: none;
  }

  .direction-r .time-wrapper {
    float: none;
  }

  .desc {
    position: relative;
    margin: 1em 0 0 0;
    padding: 1em;
    background: rgb(245, 245, 245);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
    z-index: 15;

    br {
      display: none;
    }
  }

  .direction-l .desc,
  .direction-r .desc {
    position: relative;
    margin: 1em 1em 0 1em;
    padding: 1em;

    z-index: 15;
  }
}

@media only screen and (max-width: 989px) {

  .direction-l .desc,
  .direction-r .desc {
    margin: 0.75em auto 0;
    width: calc(100% - 4em);
    box-shadow: none;
    border-radius: 20px;
  }
}