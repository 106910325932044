// POSTS

.blogfeed {
  background: $main-colour;
  background-attachment: fixed;
  padding: 6em 0;
  text-align: center;

  h2 {
    margin: 0 0 2em 0;
    font-size: 2em;
    color: $secondary-colour;
    font-weight: 700;
  }
}

.blogposts {
  width: calc(100% - 2em);
  margin: 0 auto;
  padding: 2em 1em;
  display: inline-block;
  text-align: left;
  border-radius: $border-radius;
  background: $secondary-colour;
  overflow: hidden;
  transition: 1s;

  .blogtitle {
    font-size: 1.6em;
    font-weight: 900;
    height: 24px;
    position: relative;
    padding: 0 0 0.1em 0;
    margin: 0 0 0.2em 0;
    display: -webkit-box;
    color: $pure-white;
    -webkit-line-clamp: 1;
    overflow: hidden;
    -webkit-box-orient: vertical;
  }

  .blogcontent {
    margin: 0.6em 0 1.2em;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    padding: 0.2em 0;
    line-height: 1.2em;
    height: 54px;
    color: $pure-white;
  }

  .author {
    margin: 0 3px -5px 0;
  }

  .category {
    margin: 0 3px -5px 0;
  }

  .calendar {
    margin: 0 3px -5px 0;
  }

  .ass-img {
    height: 180px;
    width: 100%;
    object-fit: cover;
    box-shadow: 0 0 5px 0 #222;
    border-radius: $border-radius;
  }

  h3 {
    margin: 0.3em 0;
    color: #555;
    font-size: 0.7em;
  }

  h4 {
    margin: 0.7em 0;
    color: $pure-white;
    font-size: 0.7em;
    display: inline-block;
    padding: 0 1em 0 0;

    a {
      color: $pure-white;
      text-decoration: none;
    }
  }

  .more {
    margin: 2em auto 0;
    padding: 0 2em;
    display: block;
    color: $secondary-colour;
    background-color: $pure-white;
    font-size: 1.2em;
    text-decoration: none;
    letter-spacing: 0.01em;
    font-weight: bold;
    border-radius: $border-radius;
    text-align: center;
    line-height: 40px;
    height: 40px;
    box-shadow: $box-shadow-default;

    &:hover {
      background-color: $secondary-colour;
      color: $pure-white;
      box-shadow: 0 0 0 1px $pure-white;
    }
  }
}
