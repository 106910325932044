.contentbox {
  padding: 5em 0;
  color: $pure-white;
  line-height: 1.2em;
  margin: 0 auto;
  max-width: 80%;

  a {
      color: inherit;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
      margin: 0.5em 0 1em;
  }

  p {
      margin: 1em 0 1.5em;
  }

  ul {
      padding: 0 0 0 1.2em;
      margin: 2em 0 1em;
      position: relative;

      li {
          margin-bottom: 0.5em;
          position: relative;

          &:before {
              content: '';
              position: absolute;
              left: -1.1em;
              top: 0;
              bottom: 0;
              height: 5px;
              width: 5px;
              margin: auto;
              border-radius: 50%;
              background-color: $pure-white;
          }
      }
  }

  blockquote {
      padding: 2em 1.5em;
      box-shadow: inset 5px 0 0 0 $pure-white;
      margin: 1em 0 1.5em;
      background-color: rgba(0, 0, 0, 0.4);
      max-width: 80%;
  }

  pre {
      display: block;
      font-family: Consolas, monospace;
      white-space: pre;
      margin: 1em 0 1.5em;
      padding: 2em 1.5em;
      background-color: lightgray;
      white-space: pre-wrap;
      color: $pure-black;
      max-width: 80%;
  }
}

// WELCOME

#welcome {
  padding: 5em 0;
  margin: auto;
  overflow: hidden;
  position: relative;
  background: linear-gradient(180deg, $main-colour 0%, $secondary-colour 10%, $secondary-colour 95%, $main-colour 100%);

  .wrapper {
    position: relative;
  }

  .welcome-panel {
    // background: $secondary-colour;
    color: $pure-white;
    padding: 5em 3em;
    border-radius: $border-radius;

    .content-half {
      display: flex;
      gap: 7em;
      align-items: center;
    }
  }

  h2 {
    padding: 0 0 5% 0;
    font-weight: bold;
  }

  p {
    letter-spacing: 0.01em;
    line-height: 1.3;
    margin: 1.4em 0;

    &:first-of-type {
      font-weight: 600;
      font-size: 1.1em;
      margin: 1% 0;
    }
  }

  #welcome-img {
    border-radius: $border-radius-circle;
    background: radial-gradient(circle, rgb(62, 136, 91) 0%, rgb(0, 0, 0) 100%); 
  }

  .btn {
    display: inline-block;
    padding: 0.6em 2.5em;
    background-color: $pure-white;
    color: $pure-black;
    text-decoration: none;
    letter-spacing: 0.05em;
    text-align: center;
    font-weight: 700;

    &:hover {
      background-color: #ccc;
      color: $pure-white;
    }
  }
}