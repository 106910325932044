// HEADER

header {
  text-align: center;
  padding: 0 10%;
  height: 100vh;
  min-height: 700px;
  background-color: $main-colour;
  position: relative;
  z-index: 1;
  display: grid;

  img#Me {
    margin: 2em auto 4em;
    transition: 1s;
    border-radius: $border-radius-circle;
    box-shadow: $box-shadow-default;
    background: radial-gradient(
      circle,
      rgba(62, 136, 91, 1) 0%,
      rgba(0, 0, 0, 1) 100%
    );
  }

  h1 {
    color: $pure-white;
    text-shadow: 3px 3px 10px black;
    letter-spacing: 0.03em;
    text-transform: capitalize;
    line-height: 1.2em;
    font-size: 3em;
  }

  span.sub-text {
    padding: 2vh 0;
    opacity: 0.9;
    letter-spacing: 0.03em;
    display: block;
    font-size: 70%;
  }

  h2 {
    text-shadow: 3px 3px 10px black;
    position: relative;
    font-size: 1.23em;
    color: $pure-white;
  }

  .centered {
    display: grid;
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;
    position: relative;
  }
  .date-and-time {
    display: grid;
    padding: 0.8em 1.8em;
    margin: 1.6em auto 0;
    background-color: $secondary-colour;
    color: $pure-white;
    border-radius: $border-radius;
    font-size: 0.9em;
    text-transform: capitalize;

    span {
      opacity: 0.5;
      font-size: 1.2em;
      padding: 0 0.4em 0 0.4em;
    }
  }
}
