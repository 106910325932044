// HIDDEN CONTENT FOR SCREEN READERS

.visually-hidden {
    border: 0;
    padding: 0;
    margin: 0;
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px); /* IE6, IE7 support */
    clip: rect(0 0 0 0); /* Modern browsers */
    clip-path: inset(50%); /* Future-proof clipping */
    white-space: nowrap; /* Prevent word wrapping issues */
  }