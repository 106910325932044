// FOOTER

footer {
  overflow: hidden;
  width: 100%;
  padding: 0.2em 0;
  border-radius: 40px 40px 0 0px; 

  .wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  h2 {
    color: $pure-white;
    margin: 1vh auto;
  }

  p {
    margin: 0;
    line-height: 1.4;
    color: $pure-white;
    font-size: 0.9em;
    letter-spacing: 0.03em;

    img {
      margin: 0 5px;
      vertical-align: middle;
    }

    a {
      text-decoration: none;
      color: $pure-white;
      box-shadow: inset 0 -1px 0 0 $pure-white;
      padding: 0;

      &:hover {
        color: $pure-black;
        box-shadow: inset 0 -18px 0 0 $pure-white;
      }
    }
  }
}

$icon-colours: $secondary-colour;

.social-icon {
  color: $pure-white;
}

ul.social-icons {
  right: 0;
  top: 0;
  bottom: 0;
}

.social-icons li {
  vertical-align: top;
  display: inline;
  height: 100px;
  margin: 0 0.5px;
}

.social-icons a {
  color: $pure-white;
  text-decoration: none;
}

.fa-codepen {
  padding: 2px 2px;
  transition: 0.5s;
  background-color: $icon-colours;
  border-radius: 20px;
  height: 32px;
}

.fa-codepen:hover {
  background-color: #bb001b;
}

.fa-twitter {
  padding: 10px 10px;
  transition: 0.5s;
  background-color: $icon-colours;
  border-radius: 20px;
}

.fa-twitter:hover {
  background-color: #00aced;
}

.fa-github {
  padding: 6px 6px;
  transition: 0.5s;
  background-color: $icon-colours;
  border-radius: 20px;
  height: 24px;
}

.fa-github:hover {
  background-color: #5a32a3;
}

.fa-instagram {
  padding: 10px 14px;
  transition: 0.5s;
  background-color: $icon-colours;
}

.fa-instagram:hover {
  background-color: #c13584;
}
